.section-industry
  padding 24px 0 40px
  background url('../images/fv-bg2.png') center / cover
  color $white
  text-align center
  +mq('md')
    padding 24px 2.5% 40px
  +mq('sp')
    background-position top left

.industry-heading
  font-size 26px
  font-weight 700
  +mq('sp')
    font-size 20px

.industry-sub-heading
  display table
  margin 0 auto 1.5em
  padding .8em 4em
  background linear-gradient(to right, $green-light, $green-heavy)
  font-size 25px
  font-weight 700
  +mq('sp')
    width 90%
    max-width 480px
    padding .5em 0
    font-size 16px

.entertainers
  display flex
  justify-content space-around
  +mq('sp')
    flex-wrap wrap
    max-width 460px
    margin auto

.entertainer
  display flex
  justify-content center
  align-items center
  width 160px
  height 160px
  border 1px solid
  border-radius 50%
  font-size 18px
  font-weight 700
  +mq('md')
    width 14vw
    height 14vw
    font-size 1.6vw
  +mq('sp')
    width 120px
    height 120px
    margin 8px
    font-size 14px
