.section-flow
  padding 80px 0 120px
  background $flow-bg
  +mq('md')
    padding 80px 2.5% 120px

.flow-item
  display flex
  height 90px
  margin-bottom 2em
  background $white
  border-radius 80px
  overflow hidden
  +mq('sp')
    display block
    height auto
    border-radius 12px

.flow-item-heading
  display flex
  align-items center
  width 320px
  padding 4px 1.2em 4px
  background $flow-item-heading-bg
  color $navy
  font-size 20px
  font-weight 700
  +mq('md')
    width 280px
    font-size 19px
  +mq('sp')
    width 100%
    height 60px
    font-size 18px

.flow-item-heading-image
  max-width 54px
  height 100%
  margin-right 1em
  +mq('sp')
    max-width 36px

.flow-item-text
  flex 1
  display flex
  align-items center
  padding 0 1em
  font-size 17px
  line-height 1.8
  span
    font-size 15px
  +mq('sp')
    min-height 80px
    font-size 14px
    span
      font-size 12px
