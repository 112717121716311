.inner
  max-width 1100px
  margin auto

.inner-spread
  display flex
  justify-content space-around
  align-items center
  +mq('md')
    max-width 95%
    margin auto
  +mq('sp')
    flex-direction column
    justify-content center
    align-items center

.inner-spread-item
  +mq('sp')
    max-width 95%
    margin 24px auto
