html
body
  width 100%


body
  font-size 16px
  font-family $sans-serif
  // mincho
  // font-family $serif
  line-height 1.4
  position relative
  color $text-color
  background $background-color
  word-wrap break-word
  -webkit-text-size-adjust 100%
  -webkit-overflow-scrolling touch
  -webkit-font-smoothing antialiased

h1
h2
h3
h4
h5
h6
  font-weight normal

a
  color $text-color
  text-decoration none
