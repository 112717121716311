.section-issues
  padding 40px 0 80px
  text-align center
  +mq('md')
    padding 40px 2.5% 80px

.issues-heading
  color $navy
  font-size 22px
  font-weight 700
  +mq('sp')
    font-size 16px

.issues-sub-heading
  width 90%
  max-width 780px
  margin 0 auto 1.5em
  padding .5em 0
  background $grey
  color $white
  font-size 25px
  font-weight 700
  +mq('sp')
    max-width 640px
    font-size 20px

.issues
  display flex
  justify-content space-around
  +mq('sp')
    flex-direction column
    justify-content center
    align-items center

.issue
  color $navy
  font-weight 700
  +mq('sp')
    display flex
    width 100%
    max-width 400px
    margin 16px auto
    p
      text-align left

.issue-image
  margin-bottom 16px
  +mq('sp')
    width 100px
    margin-right 32px
    margin-bottom 0

.issue-image-3
  +mq('sp')
    width 77px
    margin-left 23px
