.contact
  position relative
  z-index 1
  display block
  width 360px
  padding 16px
  background linear-gradient(to right, $btn-color-light, $btn-color-heavy)
  border-radius 80px
  box-shadow 0 3px 6px rgba($text-color, .5)
  color $btn-text
  font-weight 700
  text-align center
  &::before
    content ''
    position absolute
    top 0
    left 0
    z-index -1
    display block
    width 100%
    height 100%
    padding 4px
    background white
    background-clip content-box
    border-radius 80px
    box-sizing border-box
  +mq('sp')
    width 300px
    margin auto
    font-size 14px
