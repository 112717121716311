.section-kv
  padding 40px 0 80px
  background url('../images/fv-bg.png') 90% 90% / 110%
  color $white
  +mq('md')
    padding 32px 2.5% 64px
    background-size cover

.kv-wrapper
  display flex
  justify-content space-between
  +mq('sp')
    flex-direction column

.kv-heading
  font-size 36px
  font-weight 700
  text-shadow 0 3px 6px rgba($text-color, .9)
  +mq('md')
    font-size 28px
  +mq('sp')
    font-size 24px

.kv-items
  +mq('sp')
    margin-bottom 40px

.kv-sub-heading
  font-size 22px
  font-weight 900
  text-shadow 0 3px 6px rgba($text-color, .9)
  +mq('md')
    font-size 20px
  +mq('sp')
    font-size 16px

.kv-text
  margin-bottom 1.8em
  font-size 18px
  text-shadow 0 3px 6px rgba($text-color, .9)
  +mq('md')
    font-size 14px
  +mq('sp')
    font-size 12px

.video-screen
  position relative
  width 240px
  +mq('md')
    width 180px
  +mq('sp')
    width 160px
    margin 0 auto

.kv-video
  position absolute
  width 210px
  top 12px
  left 16px
  border-radius 24px
  +mq('md')
    width 160px
    top 8px
    left 11px
    border-radius 18px
  +mq('sp')
    width 141px
    top 6px
    left 10px

.kv-video-frame
  position relative
  display block
  width 100%
  height auto
