.header-inner
  display flex
  justify-content space-between
  align-items center
  max-width 1200px
  margin auto
  +mq('md')
    padding 0 2.5%

.header-logo
  margin 12px 0

.nav-header
  display flex
  justify-content space-between
  width 600px
  +mq('md')
    position fixed
    z-index 1000
    top 0
    right 0
    bottom 0
    left 0
    background linear-gradient(-45deg, $green-light ,$green-heavy)
    display flex
    flex-direction column
    justify-content center
    align-items center
    width 100%
    pointer-events none
    opacity 0
    transition all .4s ease-out
    transition-delay .32s
    transform scale(1.1)
    filter blur(10px)
    &.is-open
      pointer-events auto
      opacity 1
      transform scale(1)
      transition-delay 0s
      filter blur(0)

.nav-item
  font-size 14px
  font-weight 700
  &::after
    content ''
    display block
    width 28px
    height 4px
    margin 8px 0 0
    background $text-color
  +mq('md')
    width 100%
    margin 16px 0
    padding 8px 0
    color $white
    font-size 21px
    font-weight 500
    text-align center
    &::after
      display none

.header-contact
.header-contact-sp
  position relative
  z-index 1
  width 180px
  padding 12px 0
  background linear-gradient(to right, $btn-color-light, $btn-color-heavy)
  border-radius 80px
  box-shadow 0 3px 6px rgba($text-color, .5)
  color $btn-text
  font-size 16px
  font-weight 700
  text-align center
  &::before
    content ''
    position absolute
    top 0
    left 0
    z-index -1
    display block
    width 100%
    height 100%
    padding 4px
    background white
    background-clip content-box
    border-radius 80px
    box-sizing border-box

.header-contact
  +mq('md')
    display none

.header-contact-sp
  display none
  margin 16px 0
  +mq('md')
    display block

.icon
  position relative
  z-index 1000
  display none
  width 29px
  height 21px
  margin-right 16px
  .bar
    position absolute
    height 3px
    background-color $navy
    right 0
    transition all .32s ease-out
  .bar-1
    top 0
    width 29px
  .bar-2
    top 9px
    width 21px
  .bar-3
    top 18px
    width 17px
  &.is-open
    .bar-1
      top 9px
      background-color $white
      transform rotate(45deg)
      width 29px
    .bar-2
      opacity 0
    .bar-3
      top 9px
      background-color $white
      transform rotate(-45deg)
      width 29px
  +mq('md')
    display block
    height 19px
    .bar
      height 2px
    &.is-open
      .bar-1
        top 9px
        background-color $white
        transform rotate(45deg)
        width 29px
      .bar-3
        top 9px
        background-color $white
        transform rotate(-45deg)
        width 29px
