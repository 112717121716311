.section-plan
  margin-top -40px
  padding 80px 0 40px
  background linear-gradient(to right, $green-light 50%, $green-heavy)
  color $white
  text-align center
  +mq('md')
    padding 80px 2.5% 40px

.plan-heading
  display table
  margin 0 auto 40px
  padding .5em 2em
  background $white
  color $navy
  font-size 20px
  font-weight 700
  +mq('sp')
    font-size 16px

.plan-sub-heading
  margin-bottom 40px
  font-size 22px
  font-weight 700
  +mq('sp')
    font-size 18px

.plan-border
  margin auto
  margin-bottom 40px
  padding .5em 3em
  font-size 18px
  font-weight 700
  +mq('sp')
    padding .5em
    font-size 14px

.plan-to-contact
  margin-bottom 40px
  font-size 22px
  font-weight 700
  +mq('sp')
    font-size 18px

.plan-contact
  margin 0 auto 40px

.plan-text
  line-height 1.8
  +mq('sp')
    font-size 12px

.plan-link
  display inline-block
  margin 0 4px
  color $white
  font-weight 700
  text-decoration underline
