.section-heading
  width 95%
  max-width 780px
  margin 0 auto 2em
  padding .5em 0
  background $section-heading-bg
  border-radius 80px
  color $white
  font-size 25px
  font-weight 700
  text-align center
  +mq('sp')
    max-width 560px
    font-size 20px
