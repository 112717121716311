@import url("http://fonts.googleapis.com/earlyaccess/notosansjp.css");
html,
body {
  width: 100%;
}
body {
  font-size: 16px;
  font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", "Yu Gothic", sans-serif;
  line-height: 1.4;
  position: relative;
  color: #333;
  background: #fff;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
a {
  color: #333;
  text-decoration: none;
}
*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
}
::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}
html {
  box-sizing: border-box;
  cursor: default;
  font-family: sans-serif;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}
body {
  margin: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
figcaption,
figure,
main {
  display: block;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
nav ol,
nav ul {
  list-style: none;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
::-moz-selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}
::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
audio,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
img {
  border-style: none;
}
svg {
  fill: currentColor;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
}
button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
textarea {
  overflow: auto;
  resize: vertical;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details,
menu {
  display: block;
}
summary {
  display: list-item;
}
canvas {
  display: inline-block;
}
template {
  display: none;
}
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
[hidden] {
  display: none;
}
[aria-busy="true"] {
  cursor: progress;
}
[aria-controls] {
  cursor: pointer;
}
[aria-hidden="false"][hidden] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}
[aria-hidden="false"][hidden]:focus {
  clip: auto;
}
[aria-disabled] {
  cursor: default;
}
.footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  padding: 80px 0;
}
@media only screen and (max-width: 1200px) {
  .footer-inner {
    padding: 80px 2.5%;
  }
}
@media only screen and (max-width: 800px) {
  .footer-inner {
    display: block;
  }
}
@media only screen and (max-width: 800px) {
  .footer-nav {
    margin-bottom: 54px;
  }
}
.footer-nav-item {
  margin-right: 2em;
  font-size: 14px;
}
@media only screen and (max-width: 800px) {
  .footer-nav-item {
    display: block;
    margin-bottom: 1em;
  }
}
.footer-bottom {
  height: 50px;
  background: #222;
}
.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .header-inner {
    padding: 0 2.5%;
  }
}
.header-logo {
  margin: 12px 0;
}
.nav-header {
  display: flex;
  justify-content: space-between;
  width: 600px;
}
@media only screen and (max-width: 1200px) {
  .nav-header {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(-45deg, #1ad99f, #00b6b2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    transition: all 0.4s ease-out;
    transition-delay: 0.32s;
    transform: scale(1.1);
    filter: blur(10px);
  }
  .nav-header.is-open {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1);
    transition-delay: 0s;
    filter: blur(0);
  }
}
.nav-item {
  font-size: 14px;
  font-weight: 700;
}
.nav-item::after {
  content: '';
  display: block;
  width: 28px;
  height: 4px;
  margin: 8px 0 0;
  background: #333;
}
@media only screen and (max-width: 1200px) {
  .nav-item {
    width: 100%;
    margin: 16px 0;
    padding: 8px 0;
    color: #fff;
    font-size: 21px;
    font-weight: 500;
    text-align: center;
  }
  .nav-item::after {
    display: none;
  }
}
.header-contact,
.header-contact-sp {
  position: relative;
  z-index: 1;
  width: 180px;
  padding: 12px 0;
  background: linear-gradient(to right, #007dfa, #3333b1);
  border-radius: 80px;
  box-shadow: 0 3px 6px rgba(51,51,51,0.5);
  color: #3234b2;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.header-contact::before,
.header-contact-sp::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  padding: 4px;
  background: #fff;
  background-clip: content-box;
  border-radius: 80px;
  box-sizing: border-box;
}
@media only screen and (max-width: 1200px) {
  .header-contact {
    display: none;
  }
}
.header-contact-sp {
  display: none;
  margin: 16px 0;
}
@media only screen and (max-width: 1200px) {
  .header-contact-sp {
    display: block;
  }
}
.icon {
  position: relative;
  z-index: 1000;
  display: none;
  width: 29px;
  height: 21px;
  margin-right: 16px;
}
.icon .bar {
  position: absolute;
  height: 3px;
  background-color: #2d2d94;
  right: 0;
  transition: all 0.32s ease-out;
}
.icon .bar-1 {
  top: 0;
  width: 29px;
}
.icon .bar-2 {
  top: 9px;
  width: 21px;
}
.icon .bar-3 {
  top: 18px;
  width: 17px;
}
.icon.is-open .bar-1 {
  top: 9px;
  background-color: #fff;
  transform: rotate(45deg);
  width: 29px;
}
.icon.is-open .bar-2 {
  opacity: 0;
}
.icon.is-open .bar-3 {
  top: 9px;
  background-color: #fff;
  transform: rotate(-45deg);
  width: 29px;
}
@media only screen and (max-width: 1200px) {
  .icon {
    display: block;
    height: 19px;
  }
  .icon .bar {
    height: 2px;
  }
  .icon.is-open .bar-1 {
    top: 9px;
    background-color: #fff;
    transform: rotate(45deg);
    width: 29px;
  }
  .icon.is-open .bar-3 {
    top: 9px;
    background-color: #fff;
    transform: rotate(-45deg);
    width: 29px;
  }
}
.inner {
  max-width: 1100px;
  margin: auto;
}
.inner-spread {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media only screen and (max-width: 1200px) {
  .inner-spread {
    max-width: 95%;
    margin: auto;
  }
}
@media only screen and (max-width: 800px) {
  .inner-spread {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 800px) {
  .inner-spread-item {
    max-width: 95%;
    margin: 24px auto;
  }
}
.border-around {
  display: table;
  padding: 0.5em 1em;
  border: 1px solid;
}
@media only screen and (max-width: 800px) {
  .border-around {
    padding: 0.5em;
  }
}
.contact {
  position: relative;
  z-index: 1;
  display: block;
  width: 360px;
  padding: 16px;
  background: linear-gradient(to right, #007dfa, #3333b1);
  border-radius: 80px;
  box-shadow: 0 3px 6px rgba(51,51,51,0.5);
  color: #3234b2;
  font-weight: 700;
  text-align: center;
}
.contact::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  padding: 4px;
  background: #fff;
  background-clip: content-box;
  border-radius: 80px;
  box-sizing: border-box;
}
@media only screen and (max-width: 800px) {
  .contact {
    width: 300px;
    margin: auto;
    font-size: 14px;
  }
}
.section-heading {
  width: 95%;
  max-width: 780px;
  margin: 0 auto 2em;
  padding: 0.5em 0;
  background: #2d2d94;
  border-radius: 80px;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}
@media only screen and (max-width: 800px) {
  .section-heading {
    max-width: 560px;
    font-size: 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .is-pc {
    display: none;
  }
}
.is-tb {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .is-tb {
    display: block;
  }
}
@media only screen and (max-width: 800px) {
  .is-tb {
    display: none;
  }
}
.is-sp {
  display: none;
}
@media only screen and (max-width: 800px) {
  .is-sp {
    display: block;
  }
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.section-kv {
  padding: 40px 0 80px;
  background: url("../images/fv-bg.png") 90% 90%/110%;
  color: #fff;
}
@media only screen and (max-width: 1200px) {
  .section-kv {
    padding: 32px 2.5% 64px;
    background-size: cover;
  }
}
.kv-wrapper {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 800px) {
  .kv-wrapper {
    flex-direction: column;
  }
}
.kv-heading {
  font-size: 36px;
  font-weight: 700;
  text-shadow: 0 3px 6px rgba(51,51,51,0.9);
}
@media only screen and (max-width: 1200px) {
  .kv-heading {
    font-size: 28px;
  }
}
@media only screen and (max-width: 800px) {
  .kv-heading {
    font-size: 24px;
  }
}
@media only screen and (max-width: 800px) {
  .kv-items {
    margin-bottom: 40px;
  }
}
.kv-sub-heading {
  font-size: 22px;
  font-weight: 900;
  text-shadow: 0 3px 6px rgba(51,51,51,0.9);
}
@media only screen and (max-width: 1200px) {
  .kv-sub-heading {
    font-size: 20px;
  }
}
@media only screen and (max-width: 800px) {
  .kv-sub-heading {
    font-size: 16px;
  }
}
.kv-text {
  margin-bottom: 1.8em;
  font-size: 18px;
  text-shadow: 0 3px 6px rgba(51,51,51,0.9);
}
@media only screen and (max-width: 1200px) {
  .kv-text {
    font-size: 14px;
  }
}
@media only screen and (max-width: 800px) {
  .kv-text {
    font-size: 12px;
  }
}
.video-screen {
  position: relative;
  width: 240px;
}
@media only screen and (max-width: 1200px) {
  .video-screen {
    width: 180px;
  }
}
@media only screen and (max-width: 800px) {
  .video-screen {
    width: 160px;
    margin: 0 auto;
  }
}
.kv-video {
  position: absolute;
  width: 210px;
  top: 12px;
  left: 16px;
  border-radius: 24px;
}
@media only screen and (max-width: 1200px) {
  .kv-video {
    width: 160px;
    top: 8px;
    left: 11px;
    border-radius: 18px;
  }
}
@media only screen and (max-width: 800px) {
  .kv-video {
    width: 141px;
    top: 6px;
    left: 10px;
  }
}
.kv-video-frame {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
.section-feature {
  position: relative;
  padding: 24px 0 16px;
  background: #2d2d94;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 3px 6px rgba(0,0,0,0.5);
  line-height: 1.8;
}
@media only screen and (max-width: 1200px) {
  .section-feature {
    padding: 24px 2.5% 16px;
  }
}
@media only screen and (max-width: 800px) {
  .section-feature {
    font-size: 20px;
  }
}
.feature-point {
  position: relative;
  z-index: 1;
  display: inline-block;
}
.feature-point::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 6px;
  z-index: -1;
  display: block;
  width: 100%;
  height: 12px;
  background: #eb0000;
}
@media only screen and (max-width: 800px) {
  .feature-point::after {
    height: 6px;
  }
}
.feature-triangle {
  content: '';
  position: relative;
  z-index: 1;
  display: block;
  width: 0;
  height: 0;
  margin: -1px auto 0;
  border-style: solid;
  border-width: 40px 60px 0 60px;
  border-color: #2d2d94 transparent transparent transparent;
}
@media only screen and (max-width: 800px) {
  .feature-triangle {
    border-width: 20px 30px 0 30px;
  }
}
.section-feature1 {
  margin-top: -40px;
  padding: 80px 0 0;
  background: radial-gradient(at center 10%, #00bab6 50%, #007c80);
}
.feature1-heading {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.feature1-heading::after {
  content: '';
  display: block;
  width: 120px;
  height: 3px;
  margin: 30px auto 0;
  background: #fff;
}
@media only screen and (max-width: 800px) {
  .feature1-heading {
    font-size: 20px;
  }
}
@media only screen and (max-width: 800px) {
  .feature1-inner {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}
.feature-sub-heading {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.6;
}
@media only screen and (max-width: 800px) {
  .feature-sub-heading {
    font-size: 19px;
  }
}
.feature-text {
  color: #fff;
  font-size: 17px;
  line-height: 1.8;
}
@media only screen and (max-width: 800px) {
  .feature-text {
    font-size: 14px;
  }
}
.feature-image {
  display: block;
  max-width: 90%;
  margin: auto;
}
@media only screen and (max-width: 800px) {
  .feature-image {
    max-width: 220px;
  }
}
.feature-image-1 {
  width: 420px;
}
@media only screen and (max-width: 800px) {
  .feature-image-1 {
    width: 300px;
    margin-bottom: -24px;
  }
}
.section-feature2 {
  padding: 80px 0;
  background: #4fcec9;
}
@media only screen and (max-width: 800px) {
  .feature-image-2 {
    max-width: 180px;
  }
}
.section-feature3 {
  padding: 120px 0;
  background: url("../images/feature-3.png") center/cover;
}
.section-feature4 {
  padding: 120px 0;
  background: #4fb1ce;
}
.section-flow {
  padding: 80px 0 120px;
  background: #eaf1ff;
}
@media only screen and (max-width: 1200px) {
  .section-flow {
    padding: 80px 2.5% 120px;
  }
}
.flow-item {
  display: flex;
  height: 90px;
  margin-bottom: 2em;
  background: #fff;
  border-radius: 80px;
  overflow: hidden;
}
@media only screen and (max-width: 800px) {
  .flow-item {
    display: block;
    height: auto;
    border-radius: 12px;
  }
}
.flow-item-heading {
  display: flex;
  align-items: center;
  width: 320px;
  padding: 4px 1.2em 4px;
  background: #d0e0ff;
  color: #2d2d94;
  font-size: 20px;
  font-weight: 700;
}
@media only screen and (max-width: 1200px) {
  .flow-item-heading {
    width: 280px;
    font-size: 19px;
  }
}
@media only screen and (max-width: 800px) {
  .flow-item-heading {
    width: 100%;
    height: 60px;
    font-size: 18px;
  }
}
.flow-item-heading-image {
  max-width: 54px;
  height: 100%;
  margin-right: 1em;
}
@media only screen and (max-width: 800px) {
  .flow-item-heading-image {
    max-width: 36px;
  }
}
.flow-item-text {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 1em;
  font-size: 17px;
  line-height: 1.8;
}
.flow-item-text span {
  font-size: 15px;
}
@media only screen and (max-width: 800px) {
  .flow-item-text {
    min-height: 80px;
    font-size: 14px;
  }
  .flow-item-text span {
    font-size: 12px;
  }
}
.section-industry {
  padding: 24px 0 40px;
  background: url("../images/fv-bg2.png") center/cover;
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  .section-industry {
    padding: 24px 2.5% 40px;
  }
}
@media only screen and (max-width: 800px) {
  .section-industry {
    background-position: top left;
  }
}
.industry-heading {
  font-size: 26px;
  font-weight: 700;
}
@media only screen and (max-width: 800px) {
  .industry-heading {
    font-size: 20px;
  }
}
.industry-sub-heading {
  display: table;
  margin: 0 auto 1.5em;
  padding: 0.8em 4em;
  background: linear-gradient(to right, #1ad99f, #00b6b2);
  font-size: 25px;
  font-weight: 700;
}
@media only screen and (max-width: 800px) {
  .industry-sub-heading {
    width: 90%;
    max-width: 480px;
    padding: 0.5em 0;
    font-size: 16px;
  }
}
.entertainers {
  display: flex;
  justify-content: space-around;
}
@media only screen and (max-width: 800px) {
  .entertainers {
    flex-wrap: wrap;
    max-width: 460px;
    margin: auto;
  }
}
.entertainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  border: 1px solid;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 700;
}
@media only screen and (max-width: 1200px) {
  .entertainer {
    width: 14vw;
    height: 14vw;
    font-size: 1.6vw;
  }
}
@media only screen and (max-width: 800px) {
  .entertainer {
    width: 120px;
    height: 120px;
    margin: 8px;
    font-size: 14px;
  }
}
.section-issues {
  padding: 40px 0 80px;
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  .section-issues {
    padding: 40px 2.5% 80px;
  }
}
.issues-heading {
  color: #2d2d94;
  font-size: 22px;
  font-weight: 700;
}
@media only screen and (max-width: 800px) {
  .issues-heading {
    font-size: 16px;
  }
}
.issues-sub-heading {
  width: 90%;
  max-width: 780px;
  margin: 0 auto 1.5em;
  padding: 0.5em 0;
  background: #cecece;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
}
@media only screen and (max-width: 800px) {
  .issues-sub-heading {
    max-width: 640px;
    font-size: 20px;
  }
}
.issues {
  display: flex;
  justify-content: space-around;
}
@media only screen and (max-width: 800px) {
  .issues {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.issue {
  color: #2d2d94;
  font-weight: 700;
}
@media only screen and (max-width: 800px) {
  .issue {
    display: flex;
    width: 100%;
    max-width: 400px;
    margin: 16px auto;
  }
  .issue p {
    text-align: left;
  }
}
.issue-image {
  margin-bottom: 16px;
}
@media only screen and (max-width: 800px) {
  .issue-image {
    width: 100px;
    margin-right: 32px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 800px) {
  .issue-image-3 {
    width: 77px;
    margin-left: 23px;
  }
}
.section-plan {
  margin-top: -40px;
  padding: 80px 0 40px;
  background: linear-gradient(to right, #1ad99f 50%, #00b6b2);
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  .section-plan {
    padding: 80px 2.5% 40px;
  }
}
.plan-heading {
  display: table;
  margin: 0 auto 40px;
  padding: 0.5em 2em;
  background: #fff;
  color: #2d2d94;
  font-size: 20px;
  font-weight: 700;
}
@media only screen and (max-width: 800px) {
  .plan-heading {
    font-size: 16px;
  }
}
.plan-sub-heading {
  margin-bottom: 40px;
  font-size: 22px;
  font-weight: 700;
}
@media only screen and (max-width: 800px) {
  .plan-sub-heading {
    font-size: 18px;
  }
}
.plan-border {
  margin: auto;
  margin-bottom: 40px;
  padding: 0.5em 3em;
  font-size: 18px;
  font-weight: 700;
}
@media only screen and (max-width: 800px) {
  .plan-border {
    padding: 0.5em;
    font-size: 14px;
  }
}
.plan-to-contact {
  margin-bottom: 40px;
  font-size: 22px;
  font-weight: 700;
}
@media only screen and (max-width: 800px) {
  .plan-to-contact {
    font-size: 18px;
  }
}
.plan-contact {
  margin: 0 auto 40px;
}
.plan-text {
  line-height: 1.8;
}
@media only screen and (max-width: 800px) {
  .plan-text {
    font-size: 12px;
  }
}
.plan-link {
  display: inline-block;
  margin: 0 4px;
  color: #fff;
  font-weight: 700;
  text-decoration: underline;
}
.section-preparation {
  position: relative;
  background: #fff;
  padding: 80px 0;
}
@media only screen and (max-width: 1200px) {
  .section-preparation {
    padding: 80px 2.5%;
  }
}
.preparation-inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}
@media only screen and (max-width: 800px) {
  .preparation-inner {
    flex-direction: column;
    align-items: center;
  }
}
.preparation-item {
  width: 33%;
  max-width: 300px;
}
@media only screen and (max-width: 800px) {
  .preparation-item {
    width: auto;
    margin: 16px auto;
  }
}
.preparation-heading {
  margin-bottom: 1em;
  color: #2d2d94;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  .preparation-heading {
    font-size: 17px;
  }
}
@media only screen and (max-width: 800px) {
  .preparation-heading {
    font-size: 18px;
  }
}
.preparation-item-image {
  width: 100%;
  margin-bottom: 1em;
}
.preparation-item-text {
  font-size: 15px;
  line-height: 1.6;
}
@media only screen and (max-width: 800px) {
  .preparation-item-text {
    font-size: 14px;
  }
}
.preparation-image {
  display: block;
  margin: 0 auto 40px;
}
.preparation-text {
  color: #2d2d94;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 2;
}
@media only screen and (max-width: 800px) {
  .preparation-text {
    font-size: 16px;
  }
}
.preparation-triangle {
  content: '';
  position: relative;
  z-index: 1;
  display: block;
  width: 0;
  height: 0;
  margin: -1px auto 0;
  border-style: solid;
  border-width: 40px 60px 0 60px;
  border-color: #fff transparent transparent transparent;
}
@media only screen and (max-width: 800px) {
  .preparation-triangle {
    border-width: 20px 30px 0 30px;
  }
}
