.footer-inner
  display flex
  justify-content space-between
  align-items center
  max-width 1200px
  margin auto
  padding 80px 0
  +mq('md')
    padding 80px 2.5%
  +mq('sp')
    display block

.footer-nav
  +mq('sp')
    margin-bottom 54px

.footer-nav-item
  margin-right 2em
  font-size 14px
  +mq('sp')
    display block
    margin-bottom 1em

.footer-bottom
  height 50px
  background $footer-bottom
