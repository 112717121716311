.section-preparation
  position relative
  background $white
  padding 80px 0
  +mq('md')
    padding 80px 2.5%

.preparation-inner
  display flex
  justify-content space-between
  margin-bottom 80px
  +mq('sp')
    flex-direction column
    align-items center

.preparation-item
  width 33%
  max-width 300px
  +mq('sp')
    width auto
    margin 16px auto

.preparation-heading
  margin-bottom 1em
  color $navy
  font-size 20px
  font-weight 700
  text-align center
  +mq('md')
    font-size 17px
  +mq('sp')
    font-size 18px

.preparation-item-image
  width 100%
  margin-bottom 1em

.preparation-item-text
  font-size 15px
  line-height 1.6
  +mq('sp')
    font-size 14px

.preparation-image
  display block
  margin 0 auto 40px

.preparation-text
  color $navy
  font-size 18px
  font-weight 700
  text-align center
  line-height 2
  +mq('sp')
    font-size 16px

.preparation-triangle
  content ''
  position relative
  z-index 1
  display block
  width 0
  height 0
  margin -1px auto 0
  border-style solid
  border-width 40px 60px 0 60px
  border-color $white transparent transparent transparent
  +mq('sp')
    border-width 20px 30px 0 30px
