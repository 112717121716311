.section-feature
  position relative
  padding 24px 0 16px
  background $navy
  color $white
  font-size 30px
  font-weight 700
  text-align center
  text-shadow 0 3px 6px rgba($black, .5)
  line-height 1.8
  +mq('md')
    padding 24px 2.5% 16px
  +mq('sp')
    font-size 20px

.feature-point
  position relative
  z-index 1
  display inline-block
  &::after
    content ''
    position absolute
    left 0
    bottom 6px
    z-index -1
    display block
    width 100%
    height 12px
    background $red
  +mq('sp')
    &::after
      height 6px

.feature-triangle
  content ''
  position relative
  z-index 1
  display block
  width 0
  height 0
  margin -1px auto 0
  border-style solid
  border-width 40px 60px 0 60px
  border-color $navy transparent transparent transparent
  +mq('sp')
    border-width 20px 30px 0 30px

.section-feature1
  margin-top -40px
  padding 80px 0 0
  background radial-gradient(at center 10%, $feature-1-light 50%, $feature-1-heavy)

.feature1-heading
  color $white
  font-size 30px
  font-weight 700
  text-align center
  &::after
    content ''
    display block
    width 120px
    height 3px
    margin 30px auto 0
    background $white
  +mq('sp')
    font-size 20px

.feature1-inner
  +mq('sp')
    flex-direction column-reverse
    justify-content center
    align-items center

.feature-sub-heading
  color $white
  font-size 30px
  font-weight 700
  line-height 1.6
  +mq('sp')
    font-size 19px

.feature-text
  color $white
  font-size 17px
  line-height 1.8
  +mq('sp')
    font-size 14px

.feature-image
  display block
  max-width 90%
  margin auto
  +mq('sp')
    max-width 220px

.feature-image-1
  width 420px
  +mq('sp')
    width 300px
    margin-bottom -24px

.section-feature2
  padding 80px 0
  background $feature-2

.feature-image-2
  +mq('sp')
    max-width 180px

.section-feature3
  padding 120px 0
  background url(../images/feature-3.png) center / cover

.section-feature4
  padding 120px 0
  background $feature-4
